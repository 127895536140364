import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  TabsAlignment as ALIGNMENT,
  TabsSkin as SKIN,
  Tabs,
  TabsVariant as VARIANT,
} from 'wix-ui-tpa/cssVars';
import { useSettings } from '@wix/tpa-settings/react';
import { DataHooks } from '../consts';
import { classes, st } from './TabsFilter.st.css';
import settingsParams from '../../../../settingsParams';
import { AlignmentOptions } from '../../../../../../types/types';
import { useWidgetActions } from '../../../hooks/useWidgetActions';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';

const mapAlignmentOptionsToTabsAlignment = {
  [AlignmentOptions.CENTER]: ALIGNMENT.center,
  [AlignmentOptions.LEFT]: ALIGNMENT.left,
  [AlignmentOptions.RIGHT]: ALIGNMENT.right,
};

export default function TabsFilter() {
  const settings = useSettings();
  const { filterOptions } = useWidgetViewModel();
  const { onFilterOptionSelected } = useWidgetActions();
  const { isMobile } = useEnvironment();

  const alignment = settings.get(settingsParams.listFilterAlignment);
  const variant = settings.get(settingsParams.isListFilterStretched)
    ? VARIANT.fullWidth
    : VARIANT.fit;
  const activeTabIndex = filterOptions.findIndex(
    ({ isSelected }) => isSelected,
  );

  return (
    <Tabs
      data-hook={DataHooks.Tabs}
      className={st(classes.root, { isMobile })}
      skin={SKIN.fitUnderline}
      alignment={mapAlignmentOptionsToTabsAlignment[alignment]}
      variant={variant}
      activeTabIndex={activeTabIndex}
      onTabClick={(selectedTabIndex) => {
        onFilterOptionSelected(filterOptions[selectedTabIndex]);
      }}
      items={filterOptions}
    />
  );
}
